import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './assets/themes/kadu/assets/css/bootstrap.min.css';
import './assets/themes/kadu/assets/css/all.min.css';
import './assets/themes/kadu/assets/css/animate.css';
import './assets/themes/kadu/assets/css/flaticon_mycollection.css';
import './assets/themes/kadu/assets/css/swiper.min.css';
import './assets/themes/kadu/assets/css/nice-select.css';
import './assets/themes/kadu/assets/css/magnific-popup.css';
import './assets/themes/kadu/assets/css/main.css';
import './assets/themes/kadu/style.css';
import './assets/plugins/contact-form-7/includes/css/styles.css';
import './assets/plugins/tutor/assets/css/tutor-icon.min.css';
import './assets/plugins/tutor/assets/css/tutor.min.css';
import './assets/plugins/tutor/assets/css/tutor-front.min.css';
import './assets/plugins/elementor/assets/lib/eicons/css/elementor-icons.min.css';
import './assets/uploads/elementor/css/custom-frontend-lite.min.css';
import './assets/uploads/elementor/css/post-15.css';
import './assets/uploads/elementor/css/global.css';
import './assets/uploads/elementor/css/post-14.css';
import reportWebVitals from './reportWebVitals'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
