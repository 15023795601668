import React from 'react';

function Students() {
  return (
    <div>
      <h1>Students Page</h1>
      <p>This is the Students page.</p>
    </div>
  );
}

export default Students;
