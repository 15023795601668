import React from 'react';
import BG from '../../assets/imgs/banner-usa.png';

function USAPage() {

  const bg = BG;

  return (
    <div>
        <div id="page" className="site site_wrapper">
            <div
              className="breadcrumb-area bg-default  pt-130 pb-130"
              style={{ marginTop: 166 }}
              data-background={bg}
            >
              <div className="container kd-container-1">
                <div className="row">
                  <div className="col-xxl-12">
                    <div className="breadcrumb-wrap"></div>
                  </div>
                </div>
              </div>
            </div>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-e59fe91 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="e59fe91"
              data-element_type="section"
              data-settings='{"background_background":"classic"}'
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-106a241"
                  data-id="106a241"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-06bbe7e elementor-widget elementor-widget-go-s-heading"
                      data-id="06bbe7e"
                      data-element_type="widget"
                      data-widget_type="go-s-heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="prthalign" style={{ margin: "2rem 0" }}>
                          <h1 className="elementor-gt-heading text-center kd-section-title-1 kd-title-ani kd-split-text">
                            Study In United Kingdom
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-495e7eb elementor-widget elementor-widget-go-pricing-paln"
                      data-id="495e7eb"
                      data-element_type="widget"
                      data-widget_type="go-pricing-paln.default"
                    >
                      <div className="elementor-widget-container">
                        <div
                          className="kd-price-4-content-wrap d-block"
                          style={{ paddingBottom: 70 }}
                        >
                          <div className="kd-price-4-content-bg img-cover fix txaa-scalex-down">
                            <img
                              decoding="async"
                              src="https://universitybureau.com/content/uploads/2024/06/p3-item-bg-1.webp"
                              alt=""
                            />
                          </div>
                          <div className="kd-price-4-content">
                            <h5
                              className="title kd-heading-1 kd-font-900 has-clr-white wow"
                              data-splitting=""
                            >
                              Overview
                            </h5>
                            <p
                              className="disc kd-para-1 has-clr-white wow "
                              data-splitting=""
                            >
                              The US is well known for having a rich and diverse educational system that provides students from all over the world with a wealth of options. From elementary school to graduate school, the American educational system is known for its adaptability, creativity, and focus on real-world learning and research. Nearly two out of every three international students who are considering studying abroad rank the United States among their top three destinations of choice. This demonstrates the strong desire of many overseas students to study in the United States.
                            </p>
                          </div>
                          <div className="kd-price-4-content-list">
                            <h4
                              className="title kd-heading-1 kd-font-900 has-clr-white wow"
                              data-splitting=""
                            >
                              benefits of United Kingdom
                            </h4>
                            <ul className="kd-list-1 kd-heading-1 kd-font-800">
                              <li>
                                <i className="fa-solid fa-circle-check" />
                                <span className="wow" data-splitting="">
                                  World-Class Education
                                </span>
                              </li>
                              <li>
                                <i className="fa-solid fa-circle-check" />
                                <span className="wow" data-splitting="">
                                  Research Opportunities
                                </span>
                              </li>
                              <li>
                                <i className="fa-solid fa-circle-check" />
                                <span className="wow" data-splitting="">
                                  Career Opportunities
                                </span>
                              </li>
                              <li>
                                <i className="fa-solid fa-circle-check" />
                                <span className="wow" data-splitting="">
                                  Personal Development
                                </span>
                              </li>
                              <li>
                                <i className="fa-solid fa-circle-check" />
                                <span className="wow" data-splitting="">
                                  Variety of Programs
                                </span>
                              </li>
                              <li>
                                <i className="fa-solid fa-circle-check" />
                                <span className="wow" data-splitting="">
                                  Cultural Diversity
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-e59fe91 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="e59fe91"
              data-element_type="section"
              data-settings='{"background_background":"classic"}'
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-106a241"
                  data-id="106a241"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-06bbe7e elementor-widget elementor-widget-go-s-heading"
                      data-id="06bbe7e"
                      data-element_type="widget"
                      data-widget_type="go-s-heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="prthalign" style={{ margin: "2rem 0" }}>
                          <h1 className="elementor-gt-heading text-center kd-section-title-1 kd-title-ani kd-split-text">
                            Why Choose United Kingdom for Your <br />
                            Academic Pursuits
                          </h1>
                          <p>
                          Choosing the United States for your academic pursuits can be a rewarding decision for several reasons. The USA is well known for its top-notch educational system, a wide variety of institutions, and exciting cultural offerings. Here are some compelling reasons to consider the USA for your academic journey:
                          </p>
                        </div>
                      </div>
                      <div className="elementor-widget-container">
                        <div
                          id="categories-2"
                          className="widget_categories sidebar-widget"
                        >
                          <ul>
                            <li className="cat-item cat-item-25">
                              <a href="/#">
                                <div style={{ padding: 10 }}>
                                  <b>World-Renowned Institutions:</b>
                                  <br />
                                  The USA has some of the world's top-ranked universities and colleges known for their academic excellence, cutting-edge research facilities, and esteemed faculty members.
                                </div>
                              </a>
                            </li>
                            <li className="cat-item cat-item-25">
                              <a href="/#">
                                <div style={{ padding: 10 }}>
                                  <b>Wide Range of Programs:</b>
                                  <br />
                                  American universities offer an extensive array of programs across various fields of study, providing you with flexibility whether it is in the fields of business, humanities, arts, or any other discipline, there's a program that matches your goals.
                                </div>
                              </a>
                            </li>
                            <li className="cat-item cat-item-25">
                              <a href="/#">
                                <div style={{ padding: 10 }}>
                                  <b>Innovative Teaching Methods:</b>
                                  <br />
                                  Universities in the USA have innovative teaching methods that prioritize hands-on learning, critical thinking, and practical application of knowledge.
                                </div>
                              </a>
                            </li>
                            <li className="cat-item cat-item-25">
                              <a href="/#">
                                <div style={{ padding: 10 }}>
                                  <b>Cultural Diversity:</b>
                                  <br />
                                  Studying in the USA exposes you to a rich cultural tapestry with students from diverse backgrounds and perspectives which fosters cross-cultural understanding, global awareness, and the development of interpersonal skills.
                                </div>
                              </a>
                            </li>
                            <li className="cat-item cat-item-25">
                              <a href="/#">
                                <div style={{ padding: 10 }}>
                                  <b>Networking Opportunities:</b>
                                  <br />
                                  It boasts extensive alumni networks and connections with industry leaders, providing you with valuable networking opportunities and access to mentorship, internships, and career resources.
                                </div>
                              </a>
                            </li>
                            <li className="cat-item cat-item-25">
                              <a href="/#">
                                <div style={{ padding: 10 }}>
                                  <b>Internship and Employment Prospects:</b>
                                  <br />
                                  The USA offers abundant internship and employment prospects across various industries, allowing you to gain practical experience, build your resume, and kick-start your career while studying.
                                </div>
                              </a>
                            </li>
                            <li className="cat-item cat-item-25">
                              <a href="/#">
                                <div style={{ padding: 10 }}>
                                  <b>Quality of Life:</b>
                                  <br />
                                  Studying in the USA offers a high quality of life with access to world-class amenities, vibrant cities, cultural attractions, and recreational activities.
                                </div>
                              </a>
                            </li>
                            <li className="cat-item cat-item-25">
                              <a href="/#">
                                <div style={{ padding: 10 }}>
                                  <b>Global Recognition:</b>
                                  <br />
                                  A degree from a US institution is highly regarded worldwide and opens doors to a wide range of career opportunities both in the USA and internationally.
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-3163e91 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
              data-id="3163e91"
              data-element_type="section"
              style={{
                backgroundImage:
                  "url(https://universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
                backgroundSize: "cover",
                marginTop: "2rem"
              }}
            >
              <div className="elementor-container elementor-column-gap-no">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-888d83f"
                  data-id="888d83f"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-789f478 elementor-widget elementor-widget-go-pricing-tab"
                      data-id="789f478"
                      data-element_type="widget"
                      data-widget_type="go-pricing-tab.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="kd-price-1-area">
                          <div className="container kd-conainer-1">
                            <div className="kd-price-1-row d-flex">
                              {/* left-side */}
                              <div
                                className="kd-price-1-left w-100"
                                style={{
                                  padding: 50,
                                  justifyContent: "space-between",
                                  gap: 200
                                }}
                              >
                                {/* tabs-btn */}
                                <ul
                                  className="kd-price-1-tabs-btn"
                                  id="myTab"
                                  role="tablist"
                                >
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className="nav-link active kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                      id="home-tab0"
                                      data-bs-toggle="tab"
                                      data-bs-target="#home0"
                                      type="button"
                                      role="tab"
                                      aria-controls="home0"
                                      aria-selected="true"
                                    >
                                      <img
                                        decoding="async"
                                        src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                        alt=""
                                      />
                                      Foundations/pre-masters{" "}
                                    </button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className="nav-link  kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                      id="home-tab1"
                                      data-bs-toggle="tab"
                                      data-bs-target="#home1"
                                      type="button"
                                      role="tab"
                                      aria-controls="home1"
                                      aria-selected="true"
                                    >
                                      <img
                                        decoding="async"
                                        src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                        alt=""
                                      />
                                      Undergraduate
                                    </button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className="nav-link  kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                      id="home-tab2"
                                      data-bs-toggle="tab"
                                      data-bs-target="#home2"
                                      type="button"
                                      role="tab"
                                      aria-controls="home2"
                                      aria-selected="true"
                                    >
                                      <img
                                        decoding="async"
                                        src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                        alt=""
                                      />
                                      Post Graduate
                                    </button>
                                  </li>
                                </ul>
                                {/* tabs-content */}
                                <div
                                  className="tab-content kd-price-1-tabs-content"
                                  id="myTabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id="home0"
                                    role="tabpanel"
                                    aria-labelledby="home-tab0"
                                  >
                                    <div className="kd-price-1-tabs-content-wrap">
                                      <div className="">
                                        <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                          One or two semesters are part of a pre-master course.
                                          <br/>
                                          <br/>
                                          The scored 61 on the TOEFL iBT, 5.5 on the IELTS, or 44 on the PTE Academic exam
                                          <br />
                                          <br />
                                          Foundations/pre-masters Streams
                                          <br />
                                          <br />
                                        </h4>
                                        <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                          <li
                                            style={{ padding: "10px 0", color: "#fff" }}
                                          >
                                            <i className="fa-solid fa-circle-check" />
                                            Business or Pre-MBA
                                          </li>
                                          <li
                                            style={{ padding: "10px 0", color: "#fff" }}
                                          >
                                            <i className="fa-solid fa-circle-check" />
                                            Engineering
                                          </li>
                                          <li
                                            style={{ padding: "10px 0", color: "#fff" }}
                                          >
                                            <i className="fa-solid fa-circle-check" />
                                            Computer Science
                                          </li>
                                          <li
                                            style={{ padding: "10px 0", color: "#fff" }}
                                          >
                                            <i className="fa-solid fa-circle-check" />
                                            English language studies
                                          </li>
                                          <li
                                            style={{ padding: "10px 0", color: "#fff" }}
                                          >
                                            <i className="fa-solid fa-circle-check" />
                                            Management
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="tab-pane fade "
                                    id="home1"
                                    role="tabpanel"
                                    aria-labelledby="home-tab1"
                                  >
                                    <div className="kd-price-1-tabs-content-wrap">
                                      <div className="">
                                        <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                          Applicants for undergraduate programs must have achieved a minimum of 55-65% in their 12th-grade examinations.
                                          <br/>
                                          <br/>
                                          A required language proficiency level of IELTS minimum of 6 overall (with at least 6 in each section) or a TOEFL score of 70 overall is expected.
                                          <br />
                                          <br />
                                          Bachelor’s Streams
                                          <br />
                                          <br />
                                        </h4>
                                        <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                          <li
                                            style={{ padding: "10px 0", color: "#fff" }}
                                          >
                                            <i className="fa-solid fa-circle-check" />
                                            Business Management
                                          </li>
                                          <li
                                            style={{ padding: "10px 0", color: "#fff" }}
                                          >
                                            <i className="fa-solid fa-circle-check" />
                                            Computer Science/ Data Science
                                          </li>
                                          <li
                                            style={{ padding: "10px 0", color: "#fff" }}
                                          >
                                            <i className="fa-solid fa-circle-check" />
                                            Engineering/Architecture
                                          </li>
                                          <li
                                            style={{ padding: "10px 0", color: "#fff" }}
                                          >
                                            <i className="fa-solid fa-circle-check" />
                                            Sociology/Social Work
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="tab-pane fade "
                                    id="home2"
                                    role="tabpanel"
                                    aria-labelledby="home-tab2"
                                  >
                                    <div className="kd-price-1-tabs-content-wrap">
                                      <div className="">
                                        <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                          Applicants must have a minimum of 60–65% in their bachelor's degree to be admitted to graduate school.
                                          <br/>
                                          <br/>
                                          A GMAT total score of 500–550 may be required for management courses, whereas a GRE of 300 may be needed for technical courses.
                                          <br/>
                                          <br/>
                                          Language proficiency can be demonstrated through an IELTS score of 6.5 overall with no band less than 6.0, or a TOEFL score of 80.
                                          <br />
                                          <br />
                                          Postgraduate Streams
                                          <br />
                                          <br />
                                        </h4>
                                        <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                          <li
                                            style={{ padding: "10px 0", color: "#fff" }}
                                          >
                                            <i className="fa-solid fa-circle-check" />
                                            MBA
                                          </li>
                                          <li
                                            style={{ padding: "10px 0", color: "#fff" }}
                                          >
                                            <i className="fa-solid fa-circle-check" />
                                            IT and Computer Science
                                          </li>
                                          <li
                                            style={{ padding: "10px 0", color: "#fff" }}
                                          >
                                            <i className="fa-solid fa-circle-check" />
                                            Mass Communication and Media
                                          </li>
                                          <li
                                            style={{ padding: "10px 0", color: "#fff" }}
                                          >
                                            <i className="fa-solid fa-circle-check" />
                                            Law
                                          </li>
                                          <li
                                            style={{ padding: "10px 0", color: "#fff" }}
                                          >
                                            <i className="fa-solid fa-circle-check" />
                                            Hotel Management and Hospitality
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-88d3249 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="88d3249"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a06ae77"
                  data-id="a06ae77"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-aeadd90 elementor-widget elementor-widget-go-img--bx"
                      data-id="aeadd90"
                      data-element_type="widget"
                      data-widget_type="go-img--bx.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="kd-about-3-img-wrap txaa-slide-down-1">
                          <div className="kd-about-3-img img-cover fix kd-img-ani-1">
                            <img
                              decoding="async"
                              src="https://universitybureau.com/content/uploads/2024/06/a3-img-1.webp"
                              alt=""
                            />
                          </div>
                          <div className="kd-about-3-img-2 img-cover fix txaa-slide-down-1-item">
                            <img
                              decoding="async"
                              src="https://universitybureau.com/content/uploads/2024/06/a3-img-2.webp"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4203749"
                  data-id={4203749}
                  data-element_type="column"
                  style={{
                    flexDirection: "column",
                    padding: "20px 70px",
                    paddingRight: 0
                  }}
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-9ceb82e elementor-widget elementor-widget-go-s-heading"
                      data-id="9ceb82e"
                      data-element_type="widget"
                      data-widget_type="go-s-heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="prthalign">
                          <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                            Application Procedure in United Kingdom
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-66aff77 elementor-widget elementor-widget-go-list-item"
                      data-id="66aff77"
                      data-element_type="widget"
                      data-widget_type="go-list-item.default"
                    >
                      <div className="elementor-widget-container">
                        <ul
                          className="kd-list-1 kd-heading-1 kd-font-800 "
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <li className="font-c-24">
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Build your college list
                            </span>
                          </li>
                          <li className="font-c-24">
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Get your transcript evaluate
                            </span>
                          </li>
                          <li className="font-c-24">
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Prepare recommendation letter
                            </span>
                          </li>
                          <li className="font-c-24">
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Get aware of the language requirement
                            </span>
                          </li>
                          <li className="font-c-24">
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Get informed on the standardized tests
                            </span>
                          </li>
                          <li className="font-c-24">
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Send Application Form
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-19ff2bc elementor-widget elementor-widget-ft-btn"
                      data-id="19ff2bc"
                      data-element_type="widget"
                      data-widget_type="ft-btn.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="prthalign">
                          <a
                            href="/#"
                            target="_blank"
                            rel="nofollow"
                            aria-label="name"
                            className="kd-pr-btn-1 text-uppercase has-pr-clr"
                          >
                            <span
                              className="text"
                              data-back="find out more"
                              data-front="Apply Now"
                            />
                            <span className="icon">
                              <i className="fa-solid fa-arrow-right" />
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-e01eb3b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="e01eb3b"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fc8911c"
                  data-id="fc8911c"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-59eb154 elementor-widget elementor-widget-go-s-heading"
                      data-id="59eb154"
                      data-element_type="widget"
                      data-widget_type="go-s-heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="prthalign">
                          <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                            <img
                              decoding="async"
                              src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                              alt=""
                            />
                            Required Documents
                          </h6>
                          <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                            Student Visa Requirements for United Kingdom
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-7ec771b elementor-widget elementor-widget-go-ct-info"
                      data-id="7ec771b"
                      data-element_type="widget"
                      data-widget_type="go-ct-info.default"
                    >
                      <div className="elementor-widget-container">
                        <ul
                          className="kd-list-1 kd-heading-1 kd-font-500 "
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: "100%"
                          }}
                        >
                          <li className="font-c-20 custom-flex-box-1">
                            <i
                              className="fa-solid fa-circle-check font-c-40"
                              style={{ marginRight: 10 }}
                            />
                            <p className="m-0">
                              <b>Form DS-160:</b>
                              <br />
                              Complete the Online Nonimmigrant Visa Application form DS-160, available on the website of the U.S. Department of State.
                            </p>
                          </li>
                          <li className="font-c-20 custom-flex-box-1">
                            <i
                              className="fa-solid fa-circle-check font-c-40"
                              style={{ marginRight: 10 }}
                            />
                            <p className="m-0">
                              <b>Visa Fee Payment:</b>
                              <br />
                              Pay the non-refundable visa application fee, which is required for processing your visa application. The fee amount may vary depending on the type of visa and your country of citizenship.
                            </p>
                          </li>
                          <li className="font-c-20 custom-flex-box-1">
                            <i
                              className="fa-solid fa-circle-check font-c-40"
                              style={{ marginRight: 10 }}
                            />
                            <p className="m-0">
                              <b>SEVIS Fee Payment:</b>
                              <br />
                              Pay the Student and Exchange Visitor Information System (SEVIS) fee, which is required for F and M visa applicants. You will need to pay this fee before your visa interview and provide proof of payment.
                            </p>
                          </li>
                          <li className="font-c-20 custom-flex-box-1">
                            <i
                              className="fa-solid fa-circle-check font-c-40"
                              style={{ marginRight: 10 }}
                            />
                            <p className="m-0">
                              <b>I-20 Form:</b>
                              <br />
                              Obtain the Form I-20 (Certificate of Eligibility for Nonimmigrant Student Status) from the U.S. educational institution you have been accepted to. The institution will issue the Form I-20 once you have been admitted and provided proof of financial support.
                            </p>
                          </li>
                          <li className="font-c-20 custom-flex-box-1">
                            <i
                              className="fa-solid fa-circle-check font-c-40"
                              style={{ marginRight: 10 }}
                            />
                            <p className="m-0">
                              <b>Acceptance Letter:</b>
                              <br />
                              Provide the acceptance letter from the U.S. educational institution confirming your admission to a full-time academic program.
                            </p>
                          </li>
                          <li className="font-c-20 custom-flex-box-1">
                            <i
                              className="fa-solid fa-circle-check font-c-40"
                              style={{ marginRight: 10 }}
                            />
                            <p className="m-0">
                              <b>Valid Passport:</b>
                              <br />
                              Ensure that your passport is valid for at least six months beyond your intended period of stay in the United States.
                            </p>
                          </li>
                          <li className="font-c-20 custom-flex-box-1">
                            <i
                              className="fa-solid fa-circle-check font-c-40"
                              style={{ marginRight: 10 }}
                            />
                            <p className="m-0">
                              <b>Visa Interview:</b>
                              <br />
                              Schedule and attend the visa interview at the U.S. embassy or consulate in your home country. The required documents, including the DS-160 confirmation page, SEVIS fee payment receipt, Form I-20, acceptance letter, passport, and any other supporting documents.
                            </p>
                          </li>
                          <li className="font-c-20 custom-flex-box-1">
                            <i
                              className="fa-solid fa-circle-check font-c-40"
                              style={{ marginRight: 10 }}
                            />
                            <p className="m-0">
                              <b>Proof of Financial Support:</b>
                              <br />
                              The evidence of sufficient funds to cover your tuition fees, living expenses, and other related costs for the duration of your studies in the United States.
                            </p>
                          </li>
                          <li className="font-c-20 custom-flex-box-1">
                            <i
                              className="fa-solid fa-circle-check font-c-40"
                              style={{ marginRight: 10 }}
                            />
                            <p className="m-0">
                              <b>English Proficiency:</b>
                              <br />
                              Demonstrate proficiency in English by providing test scores from recognized English language proficiency exams such as the TOEFL or IELTS, if required.
                            </p>
                          </li>
                          <li className="font-c-20 custom-flex-box-1">
                            <i
                              className="fa-solid fa-circle-check font-c-40"
                              style={{ marginRight: 10 }}
                            />
                            <p className="m-0">
                              <b>Ties to Home Country:</b>
                              <br />
                              Provide evidence of strong ties to your home country, such as family, employment, or property, to demonstrate your intent to return home after completing your studies in the United States.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-d320ac4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="d320ac4"
              data-element_type="section"
              style={{
                backgroundImage:
                  "url(https://universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
                backgroundSize: "cover",
                marginTop: "2rem",
                padding: "4rem 0"
              }}
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4af12a5"
                  data-id="4af12a5"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-1649a90 elementor-widget elementor-widget-go-s-heading"
                      data-id="1649a90"
                      data-element_type="widget"
                      data-widget_type="go-s-heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="prthalign">
                          <h6
                            style={{ color: "#fff" }}
                            className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub"
                          >
                            Study fees
                          </h6>
                          <h1
                            style={{ color: "#fff" }}
                            className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text"
                          >
                            Cost to <br />
                            Study in United Kingdom
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a3b221d"
                  data-id="a3b221d"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <section
                      className="elementor-section elementor-inner-section elementor-element elementor-element-294c712 elementor-section-full_width txaa-slide-down-1 elementor-section-height-default elementor-section-height-default"
                      data-id="294c712"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-no">
                        <div
                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-69d5dc8"
                          data-id="69d5dc8"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                              data-id="9bd8b2b"
                              data-element_type="widget"
                              data-widget_type="go-info-box.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="kd-services-1-card  txaa-slide-down-1-item">
                                  <div className="icon">
                                    <img
                                      decoding="async"
                                      src="https://universitybureau.com/content/uploads/2024/05/s1-icon-1.webp"
                                      alt="List"
                                    />
                                  </div>
                                  <h4 className="title kd-heading-1 kd-font-900">
                                    <a href="/#" aria-label="name">
                                    Master's Degree
                                    </a>
                                  </h4>
                                  <p className="disc kd-para-1">$18,000 to $20,000</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-69d5dc8"
                          data-id="69d5dc8"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                              data-id="9bd8b2b"
                              data-element_type="widget"
                              data-widget_type="go-info-box.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="kd-services-1-card  txaa-slide-down-1-item">
                                  <div className="icon">
                                    <img
                                      decoding="async"
                                      src="https://universitybureau.com/content/uploads/2024/05/s1-icon-1.webp"
                                      alt="List"
                                    />
                                  </div>
                                  <h4 className="title kd-heading-1 kd-font-900">
                                    <a href="/#" aria-label="name">
                                    Bachelor's Degree
                                    </a>
                                  </h4>
                                  <p className="disc kd-para-1">$17,000 to $37,000</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-d320ac4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="d320ac4"
              data-element_type="section"
              style={{
                backgroundImage:
                  "url(https://universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
                backgroundSize: "cover",
                marginBottom: 20,
                padding: "4rem 0"
              }}
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a3b221d"
                  data-id="a3b221d"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <section
                      className="elementor-section elementor-inner-section elementor-element elementor-element-294c712 elementor-section-full_width txaa-slide-down-1 elementor-section-height-default elementor-section-height-default"
                      data-id="294c712"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-no">
                        <div
                          className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-69d5dc8"
                          data-id="69d5dc8"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                              data-id="9bd8b2b"
                              data-element_type="widget"
                              data-widget_type="go-info-box.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="kd-services-1-card  txaa-slide-down-1-item">
                                  <table className="cost-table-design">
                                    <tbody>
                                      <tr>
                                          <th><b>Expense Category</b></th>
                                          <th><b>Estimated Monthly Cost</b></th>
                                      </tr>
                                      <tr>
                                          <td>Accommodation</td>
                                          <td>$9,800 - $11,100</td>
                                      </tr>
                                      <tr>
                                          <td>Food</td>
                                          <td>$300 - $500</td>
                                      </tr>
                                      <tr>
                                          <td>Transportation</td>
                                          <td>$100 - $300</td>
                                      </tr>
                                      <tr>
                                          <td>Health insurance</td>
                                          <td>$200 - $600</td>
                                      </tr>
                                      <tr>
                                          <td>Internet and phone</td>
                                          <td>$80 - $150</td>
                                      </tr>
                                      <tr>
                                          <td>Miscellaneous</td>
                                          <td>$100 - $200</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4af12a5"
                  data-id="4af12a5"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-1649a90 elementor-widget elementor-widget-go-s-heading"
                      data-id="1649a90"
                      data-element_type="widget"
                      data-widget_type="go-s-heading.default"
                      style={{ height: "100%" }}
                    >
                      <div
                        className="elementor-widget-container"
                        style={{ height: "100%" }}
                      >
                        <div
                          className="prthalign custom-flex-box-2"
                          style={{ textAlign: "right", height: "100%" }}
                        >
                          <h6
                            style={{ color: "#fff" }}
                            className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub"
                          >
                            Study fees
                          </h6>
                          <h1
                            style={{ color: "#fff" }}
                            className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text"
                          >
                            Cost to <br />
                            Study in United Kingdom
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-e01eb3b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="e01eb3b"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fc8911c"
                  data-id="fc8911c"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-59eb154 elementor-widget elementor-widget-go-s-heading"
                      data-id="59eb154"
                      data-element_type="widget"
                      data-widget_type="go-s-heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="prthalign">
                          <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                            <img
                              decoding="async"
                              src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                              alt=""
                            />
                            FAQs
                          </h6>
                          <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                            Fequently Asked Questions
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-7ec771b elementor-widget elementor-widget-go-ct-info"
                      data-id="7ec771b"
                      data-element_type="widget"
                      data-widget_type="go-ct-info.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="tutor-mt-40 kd-course-details-content">
                          <div className="cd-video-accordion">
                            <div className="cd-video-accordion-item">
                              <h4 className="item-title tutor-accordion-item-header is-active">
                                1. What are the entry requirements for studying in the USA?
                              </h4>
                              <div className="tutor-accordion-item-body" style={{}}>
                                <div className="item-body">
                                  <p className="font-c-20">
                                  Each program and institution has different entry criteria. Typically, you will need to provide academic records, results from standardized exams (such as the SAT or GRE), recommendation letters, and evidence of your English language competence (usually in the form of the TOEFL or IELTS).
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="cd-video-accordion-item">
                              <h4 className="item-title tutor-accordion-item-header">
                                2. What are the intakes for studying in the USA?
                              </h4>
                              <div
                                className="tutor-accordion-item-body"
                              >
                                <div className="item-body">
                                Fall, which begins in September, and Spring, which begins in January, are the two main intakes. A summer intake is another possibility, albeit it's less typical.
                                </div>
                              </div>
                            </div>
                            <div className="cd-video-accordion-item">
                              <h4 className="item-title tutor-accordion-item-header">
                                3. Can I work while studying in the USA?
                              </h4>
                              <div
                                className="tutor-accordion-item-body"
                              >
                                <div className="item-body">
                                Foreign students studying in the United States are indeed permitted to work full-time during the summer months and part-time throughout the academic year on campus. Following graduation, Optional Practical Training (OPT) may provide access to off-campus work.
                                </div>
                              </div>
                            </div>
                            <div className="cd-video-accordion-item">
                              <h4 className="item-title tutor-accordion-item-header">
                                4. How long can I stay in the USA after completing my studies?
                              </h4>
                              <div
                                className="tutor-accordion-item-body"
                              >
                                <div className="item-body">
                                Depending on their field of study, international students are usually permitted to stay for an extra semester through Optional Practical Training (OPT) or STEM OPT
                                </div>
                              </div>
                            </div>
                            <div className="cd-video-accordion-item">
                              <h4 className="item-title tutor-accordion-item-header">
                                5. Can I bring my family with me while studying in the USA?
                              </h4>
                              <div
                                className="tutor-accordion-item-body"
                              >
                                <div className="item-body">
                                Students on an F-1 visa may occasionally be able to bring their spouse and kids (F-2 dependents). However, they may not work while in the U.S.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          
    </div>
  );
}
export default USAPage;
