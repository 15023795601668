import React from 'react';

function Partners() {
  return (
    <div>
      <h1>Partners Page</h1>
      <p>This is the Partners page.</p>
    </div>
  );
}

export default Partners;
