import React from "react";
import BG from "../../assets/imgs/banner-eur.png";

function Europe() {
  const bg = BG;

  return (
    <div>
      <div id="page" className="site site_wrapper">
        <div
          className="breadcrumb-area bg-default  pt-130 pb-130"
          style={{ marginTop: 166 }}
          data-background={bg}
        >
          <div className="container kd-container-1">
            <div className="row">
              <div className="col-xxl-12">
                <div className="breadcrumb-wrap"></div>
              </div>
            </div>
          </div>
        </div>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e59fe91 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e59fe91"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-106a241"
              data-id="106a241"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-06bbe7e elementor-widget elementor-widget-go-s-heading"
                  data-id="06bbe7e"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign" style={{ margin: "2rem 0" }}>
                      <h1 className="elementor-gt-heading text-center kd-section-title-1 kd-title-ani kd-split-text">
                        Study In Europe
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-495e7eb elementor-widget elementor-widget-go-pricing-paln"
                  data-id="495e7eb"
                  data-element_type="widget"
                  data-widget_type="go-pricing-paln.default"
                >
                  <div className="elementor-widget-container">
                    <div
                      className="kd-price-4-content-wrap d-block"
                      style={{ paddingBottom: 70 }}
                    >
                      <div className="kd-price-4-content-bg img-cover fix txaa-scalex-down">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/06/p3-item-bg-1.webp"
                          alt=""
                        />
                      </div>
                      <div className="kd-price-4-content">
                        <h5
                          className="title kd-heading-1 kd-font-900 has-clr-white wow"
                          data-splitting=""
                        >
                          Overview
                        </h5>
                        <p
                          className="disc kd-para-1 has-clr-white wow "
                          data-splitting=""
                        >
                          Studying in Europe offers a rich and diverse educational experience, with a multitude of cultures, languages, and academic opportunities. Renowned for its historic universities, cutting-edge research facilities, and high-quality education, Europe attracts students from around the world.
                        </p>
                      </div>
                      <div className="kd-price-4-content-list">
                        <h4
                          className="title kd-heading-1 kd-font-900 has-clr-white wow"
                          data-splitting=""
                        >
                          benefits of Europe
                        </h4>
                        <ul className="kd-list-1 kd-heading-1 kd-font-800">
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Educational Excellence
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Language Options
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Research Opportunities
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Scholarships and Funding
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Work Opportunities
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Quality of Life
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e59fe91 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e59fe91"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-106a241"
              data-id="106a241"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-06bbe7e elementor-widget elementor-widget-go-s-heading"
                  data-id="06bbe7e"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign" style={{ margin: "2rem 0" }}>
                      <h1 className="elementor-gt-heading text-center kd-section-title-1 kd-title-ani kd-split-text">
                        Why Choose Europe for Your <br />
                        Academic Pursuits
                      </h1>
                      <p>
                      Choosing Europe for your academic pursuits comes with a multitude of advantages, making it an attractive destination for students worldwide. Here are several compelling reasons to consider studying in Europe.
                      </p>
                    </div>
                  </div>
                  <div className="elementor-widget-container">
                    <div
                      id="categories-2"
                      className="widget_categories sidebar-widget"
                    >
                      <ul>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Outstanding Education:</b>
                              <br />
                              Europe is home to some of the world's oldest and most prestigious universities. Institutions like Oxford, Cambridge, and the Sorbonne have a long-standing tradition of academic excellence, providing a high-quality education.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Selection of programs:</b>
                              <br />
                              European universities offer a broad spectrum of academic programs, ranging from arts and humanities to science and technology. The diversity of courses allows students to find programs that align with their interests and career goals.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Multilingual Environment:</b>
                              <br />
                              Many European countries offer programs in English, making it accessible to international students. Additionally, studying in Europe provides the opportunity to learn or enhance proficiency in other languages, contributing to a well-rounded education.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Research Opportunities:</b>
                              <br />
                              Europe is a hub for research and innovation. Universities collaborate with leading industries and research institutions, providing students with access to cutting-edge facilities and opportunities to engage in groundbreaking research.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Cultural Vibrancy:</b>
                              <br />
                              Europe's rich history, diverse cultures, and vibrant cities create a unique cultural experience. Studying here allows students to explore historical landmarks, and museums, and experience the arts, contributing to personal and intellectual growth.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Global Networking:</b>
                              <br />
                              European universities attract students and scholars from around the world. Studying in this international environment provides opportunities to build a global network, fostering connections that can be valuable throughout your career.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Scholarships and Funding:</b>
                              <br />
                              Many European countries offer scholarships and financial support to international students. This assistance helps make education more accessible and provides financial relief to students pursuing their academic goals.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Quality of Life:</b>
                              <br />
                              Several European countries consistently rank high in terms of quality of life. Students can enjoy a high standard of living, well-developed infrastructure, and access to excellent healthcare and social services.
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-3163e91 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="3163e91"
          data-element_type="section"
          style={{
            backgroundImage:
              "url(https://universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
            backgroundSize: "cover",
            marginTop: "2rem",
          }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-888d83f"
              data-id="888d83f"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-789f478 elementor-widget elementor-widget-go-pricing-tab"
                  data-id="789f478"
                  data-element_type="widget"
                  data-widget_type="go-pricing-tab.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-price-1-area">
                      <div className="container kd-conainer-1">
                        <div className="kd-price-1-row d-flex">
                          {/* left-side */}
                          <div
                            className="kd-price-1-left w-100"
                            style={{
                              padding: 50,
                              justifyContent: "space-between",
                              gap: 200,
                            }}
                          >
                            {/* tabs-btn */}
                            <ul
                              className="kd-price-1-tabs-btn"
                              id="myTab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link active kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                  id="home-tab0"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home0"
                                  type="button"
                                  role="tab"
                                  aria-controls="home0"
                                  aria-selected="true"
                                >
                                  <img
                                    decoding="async"
                                    src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                    alt=""
                                  />
                                  Undergraduate{" "}
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link  kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                  id="home-tab1"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home1"
                                  type="button"
                                  role="tab"
                                  aria-controls="home1"
                                  aria-selected="true"
                                >
                                  <img
                                    decoding="async"
                                    src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                    alt=""
                                  />
                                  Post Graduate
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link  kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                  id="home-tab2"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home2"
                                  type="button"
                                  role="tab"
                                  aria-controls="home2"
                                  aria-selected="true"
                                >
                                  <img
                                    decoding="async"
                                    src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                    alt=""
                                  />
                                  Diploma
                                </button>
                              </li>
                            </ul>
                            {/* tabs-content */}
                            <div
                              className="tab-content kd-price-1-tabs-content"
                              id="myTabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="home0"
                                role="tabpanel"
                                aria-labelledby="home-tab0"
                              >
                                <div className="kd-price-1-tabs-content-wrap">
                                  <div className="">
                                    <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                      Grade 12th with a minimum of 50% marks.
                                      <br/>
                                      <br/>
                                      Overall IELTS score of 6.
                                      <br />
                                      <br />
                                      Undergraduate Streams
                                      <br />
                                      <br />
                                    </h4>
                                    <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Business/Management
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Arts
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Computer Science
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Engineering
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Finance/Accounting
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Economics/Mathematical research
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade "
                                id="home1"
                                role="tabpanel"
                                aria-labelledby="home-tab1"
                              >
                                <div className="kd-price-1-tabs-content-wrap">
                                  <div className="">
                                    <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                      Require a Bachelor's degree of four years, with 70%.
                                      <br/>
                                      <br/>  
                                      IELTS score: 6.5 overall, with no bands below a 6 / By the requirements of the course and the university.
                                      <br/>
                                      <br/>
                                      PBT on the TOEFL: 580–600 minimum (varies according to course) 
                                      <br />
                                      <br />
                                      Postgraduate Streams
                                      <br />
                                      <br />
                                    </h4>
                                    <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Business
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        International Business
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Engineering
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Computer Science
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Artificial Intelligence
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Data Analysis
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade "
                                id="home2"
                                role="tabpanel"
                                aria-labelledby="home-tab2"
                              >
                                <div className="kd-price-1-tabs-content-wrap">
                                  <div className="">
                                    <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                      The absolute minimum need is a diploma (Bachelor level) of at least three years.
                                      <br />
                                      <br />
                                      Diploma Streams
                                      <br />
                                      <br />
                                    </h4>
                                    <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Business Administration/Finance/Accounting
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Tours & travel management
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Hospitality Management
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Information Technology
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Engineering
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Graphic Design/Fashion
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-88d3249 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="88d3249"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a06ae77"
              data-id="a06ae77"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-aeadd90 elementor-widget elementor-widget-go-img--bx"
                  data-id="aeadd90"
                  data-element_type="widget"
                  data-widget_type="go-img--bx.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-about-3-img-wrap txaa-slide-down-1">
                      <div className="kd-about-3-img img-cover fix kd-img-ani-1">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/06/a3-img-1.webp"
                          alt=""
                        />
                      </div>
                      <div className="kd-about-3-img-2 img-cover fix txaa-slide-down-1-item">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/06/a3-img-2.webp"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4203749"
              data-id={4203749}
              data-element_type="column"
              style={{
                flexDirection: "column",
                padding: "20px 70px",
                paddingRight: 0,
              }}
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-9ceb82e elementor-widget elementor-widget-go-s-heading"
                  data-id="9ceb82e"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Application Procedure in Europe
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-66aff77 elementor-widget elementor-widget-go-list-item"
                  data-id="66aff77"
                  data-element_type="widget"
                  data-widget_type="go-list-item.default"
                >
                  <div className="elementor-widget-container">
                    <ul
                      className="kd-list-1 kd-heading-1 kd-font-800 "
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          List of universities of your choice
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Requirements and documents
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Gather test scores of proficiency exams
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Finance your Studies
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Submit the Application
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Accept Your Offer
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Fee Deposit & Accommodation
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Apply for a Visa
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-19ff2bc elementor-widget elementor-widget-ft-btn"
                  data-id="19ff2bc"
                  data-element_type="widget"
                  data-widget_type="ft-btn.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <a
                        href="/#"
                        target="_blank"
                        rel="nofollow"
                        aria-label="name"
                        className="kd-pr-btn-1 text-uppercase has-pr-clr"
                      >
                        <span
                          className="text"
                          data-back="find out more"
                          data-front="Apply Now"
                        />
                        <span className="icon">
                          <i className="fa-solid fa-arrow-right" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e01eb3b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e01eb3b"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fc8911c"
              data-id="fc8911c"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-59eb154 elementor-widget elementor-widget-go-s-heading"
                  data-id="59eb154"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                          alt=""
                        />
                        Required Documents
                      </h6>
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Student Visa Requirements for Europe
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-7ec771b elementor-widget elementor-widget-go-ct-info"
                  data-id="7ec771b"
                  data-element_type="widget"
                  data-widget_type="go-ct-info.default"
                >
                  <div className="elementor-widget-container">
                    <ul
                      className="kd-list-1 kd-heading-1 kd-font-500 "
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "100%",
                      }}
                    >
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Letter of Acceptance:</b>
                          <br />
                          A formal acceptance letter from the educational institution where you have been admitted.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Proof of Enrollment:</b>
                          <br />
                          Document confirming your enrollment in a recognized educational program.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Valid Passport:</b>
                          <br />
                          Your passport should be valid for the duration of your intended stay.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Proof of Financial Means:</b>
                          <br />
                          Evidence that you have sufficient funds to cover your tuition fees, living expenses, and other costs during your stay. This may include bank statements, scholarship confirmation, or a financial guarantee.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Health Insurance:</b>
                          <br />Proof of comprehensive health insurance coverage for the entire duration of your stay.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Language Proficiency:</b>
                          <br />Depending on the country and the language of instruction, proof of proficiency in the language of study may be required. This is often demonstrated through language proficiency test scores like IELTS or TOEFL.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Academic Qualifications:</b>
                          <br />
                          Copies of your academic transcripts, certificates, and any diplomas relevant to your admission.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Visa Application Form:</b>
                          <br />
                          Complete and submit the visa application form provided by the embassy or consulate of the country you are planning to study in.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Passport-sized Photos:</b>
                          <br />
                          Provide passport-sized photos that meet the specific requirements of the country's immigration authorities.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Travel Itinerary:</b>
                          <br />
                          Details of your travel plans, including flight reservations, are sometimes required.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Proof of Accommodation:</b>
                          <br />
                          Provide documentation confirming your accommodation arrangements during your stay. This may include a rental agreement or confirmation from the university.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Visa Application Fee:</b>
                          <br />
                          Remit the necessary application fee for a visa.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Police Clearance Certificate:</b>
                          <br />
                          Some countries may require a police clearance certificate to ensure you have no criminal record.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Medical Examination:</b>
                          <br />
                          A medical certificate proving that you are in good health may be necessary.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Prepaid Return Envelope:</b>
                          <br />
                          Some countries may require you to provide a prepaid envelope for the return of your documents.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-d320ac4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="d320ac4"
          data-element_type="section"
          style={{
            backgroundImage:
              "url(https://universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
            backgroundSize: "cover",
            marginTop: "2rem",
            padding: "4rem 0",
          }}
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4af12a5"
              data-id="4af12a5"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-1649a90 elementor-widget elementor-widget-go-s-heading"
                  data-id="1649a90"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6
                        style={{ color: "#fff" }}
                        className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub"
                      >
                        Study fees
                      </h6>
                      <h1
                        style={{ color: "#fff" }}
                        className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text"
                      >
                        Cost to <br />
                        Study in Europe
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a3b221d"
              data-id="a3b221d"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-294c712 elementor-section-full_width txaa-slide-down-1 elementor-section-height-default elementor-section-height-default"
                  data-id="294c712"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-69d5dc8"
                      data-id="69d5dc8"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                          data-id="9bd8b2b"
                          data-element_type="widget"
                          data-widget_type="go-info-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-services-1-card  txaa-slide-down-1-item">
                              <div className="icon">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/s1-icon-1.webp"
                                  alt="List"
                                />
                              </div>
                              <h4 className="title kd-heading-1 kd-font-900">
                                <a href="/#" aria-label="name">
                                  Undergraduate
                                </a>
                              </h4>
                              <p className="disc kd-para-1">
                                5,000 € - 20,000.00 €
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-69d5dc8"
                      data-id="69d5dc8"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                          data-id="9bd8b2b"
                          data-element_type="widget"
                          data-widget_type="go-info-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-services-1-card  txaa-slide-down-1-item">
                              <div className="icon">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/s1-icon-1.webp"
                                  alt="List"
                                />
                              </div>
                              <h4 className="title kd-heading-1 kd-font-900">
                                <a href="/#" aria-label="name">
                                  Postgraduate
                                </a>
                              </h4>
                              <p className="disc kd-para-1">6,000 € - 25,000.00 €</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-d320ac4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="d320ac4"
          data-element_type="section"
          style={{
            backgroundImage:
              "url(https://universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
            backgroundSize: "cover",
            marginBottom: 20,
            padding: "4rem 0",
          }}
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a3b221d"
              data-id="a3b221d"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-294c712 elementor-section-full_width txaa-slide-down-1 elementor-section-height-default elementor-section-height-default"
                  data-id="294c712"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-69d5dc8"
                      data-id="69d5dc8"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                          data-id="9bd8b2b"
                          data-element_type="widget"
                          data-widget_type="go-info-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-services-1-card  txaa-slide-down-1-item">
                              <table className="cost-table-design">
                                <tbody>
                                <tr>
                                    <th><b>Expense Category</b></th>
                                    <th><b>Estimated Monthly Cost </b></th>
                                </tr>
                                <tr>
                                    <td>Accommodation</td>
                                    <td>€500 - €1,000</td>
                                </tr>
                                <tr>
                                    <td>Food</td>
                                    <td>€250 - €400</td>
                                </tr>
                                <tr>
                                    <td>Transportation</td>
                                    <td>€70 - €150</td>
                                </tr>
                                <tr>
                                    <td>Health insurance</td>
                                    <td>€70 - €150</td>
                                </tr>
                                <tr>
                                    <td>Phone/Internet</td>
                                    <td>€40 - €60</td>
                                </tr>
                                <tr>
                                    <td>Miscellaneous</td>
                                    <td>€50 - €100</td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4af12a5"
              data-id="4af12a5"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-1649a90 elementor-widget elementor-widget-go-s-heading"
                  data-id="1649a90"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                  style={{ height: "100%" }}
                >
                  <div
                    className="elementor-widget-container"
                    style={{ height: "100%" }}
                  >
                    <div
                      className="prthalign custom-flex-box-2"
                      style={{ textAlign: "right", height: "100%" }}
                    >
                      <h6
                        style={{ color: "#fff" }}
                        className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub"
                      >
                        Study fees
                      </h6>
                      <h1
                        style={{ color: "#fff" }}
                        className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text"
                      >
                        Cost to <br />
                        Study in Europe
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e01eb3b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e01eb3b"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fc8911c"
              data-id="fc8911c"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-59eb154 elementor-widget elementor-widget-go-s-heading"
                  data-id="59eb154"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                          alt=""
                        />
                        FAQs
                      </h6>
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Fequently Asked Questions
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-7ec771b elementor-widget elementor-widget-go-ct-info"
                  data-id="7ec771b"
                  data-element_type="widget"
                  data-widget_type="go-ct-info.default"
                >
                  <div className="elementor-widget-container">
                    <div className="tutor-mt-40 kd-course-details-content">
                      <div className="cd-video-accordion">
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header is-active">
                          1. What are some advantages of studying in Europe mentioned in the introduction?
                          </h4>
                          <div className="tutor-accordion-item-body" style={{}}>
                            <div className="item-body">
                              <p className="font-c-20">
                               Educational excellence, cultural diversity, language options, research opportunities, global networking, travel and exploration, scholarships and funding, innovation and technology, quality of life, and work opportunities.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                          2. What are the streams available for undergraduate programs in Europe, and can you mention a few examples of them?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                          >
                            <div className="item-body">
                            Streams for undergraduate programs include Business/Management, Arts, Computer Science, Engineering, Finance/Accounting, and Economics/Mathematical research.
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                          3. What are the key requirements for applying to post-graduate programs in Europe?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                          >
                            <div className="item-body">
                            For post-graduate programs, you generally need a Bachelor's degree with a minimum percentage (e.g., 70%), an IELTS score of 6.5 overall, and specific scores in each band, or equivalent language proficiency test scores.
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                          4. What are the main components of the application procedure for studying in Europe?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                          >
                            <div className="item-body">
                            The application procedure includes listing universities of choice, gathering required documents, obtaining proficiency exam scores, estimating and financing your studies, submitting applications, accepting offers, paying fees arranging accommodation, and applying for a student visa.
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                          5. What are the average tuition ranges for undergraduate, post-graduate, and diploma programs in Europe?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                          >
                            <div className="item-body">
                            The average tuition range can vary, but for undergraduate programs, it is 5,000 € - 20,000 €, for post-graduate programs, it is 6,000 € - 25,000 €, and for diploma programs, it is 5,000 € - 10,000 €.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Europe;
