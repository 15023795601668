import React from "react";
import AboutForm from "./include/AboutForm";

function About() {
  return (
    <div>
      <div id="page" className="site site_wrapper">
        <div
          className="breadcrumb-area bg-default  has-breadcrumb-overlay pt-130 pb-130"
          data-background="https://universitybureau.com/content/uploads/2024/06/breadcrumb-img-1.webp"
        >
          <div className="container kd-container-1">
            <div className="row">
              <div className="col-xxl-12">
                <div className="breadcrumb-wrap">
                  <h2 className="breadcrumb-title kd-heading-1 kd-font-900 kd-h2-title-ani kd-h2-split-text">
                    About Us
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-elementor-type="wp-page"
          data-elementor-id={1845}
          className="elementor elementor-1845"
        >
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-e01eb3b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="e01eb3b"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1fe0ab2"
                data-id="1fe0ab2"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-002f5ff elementor-widget elementor-widget-go-img--bx"
                    data-id="002f5ff"
                    data-element_type="widget"
                    data-widget_type="go-img--bx.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="kd-about-2-left">
                        <div className="kd-about-2-img-1 fix img-cover kd-img-ani-1">
                          <img
                            decoding="async"
                            src="https://universitybureau.com/content/uploads/2024/05/a2-img-1.webp"
                            alt=""
                          />
                        </div>
                        <div className="kd-about-2-img-2 fix img-cover kd-img-ani-1">
                          <img
                            decoding="async"
                            src="https://universitybureau.com/content/uploads/2024/05/a2-img-2.webp"
                            alt=""
                          />
                        </div>
                        <div className="kd-about-2-img-certified txaa-roteted-1">
                          <img
                            decoding="async"
                            src="https://universitybureau.com/content/uploads/2024/05/kd-a1-certified.webp"
                            alt=""
                          />
                        </div>
                        <div className="kd-about-2-img-il-1 ">
                          <img
                            decoding="async"
                            src="https://universitybureau.com/content/uploads/2024/05/a2-il-1.webp"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-fc8911c"
                data-id="fc8911c"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-59eb154 elementor-widget elementor-widget-go-s-heading"
                    data-id="59eb154"
                    data-element_type="widget"
                    data-widget_type="go-s-heading.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="prthalign">
                        <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                          <img
                            decoding="async"
                            src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                            alt=""
                          />
                          University Bureau
                        </h6>
                        <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                          Making your studying abroad experience easy and
                          accessible
                        </h1>
                        <div
                          className="elementor-gt-desc kd-para-1 disc wow"
                          data-splitting=""
                        >
                          <p>
                            University Bureau provides international educational
                            opportunities for students, recruiters, and
                            institutions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-7ec771b elementor-widget elementor-widget-go-ct-info"
                    data-id="7ec771b"
                    data-element_type="widget"
                    data-widget_type="go-ct-info.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="kd-cta-2-content">
                        <div className="contact-item-flex d-flex align-items-center flex-wrap txaa-fade-right">
                          <div className="kd-header-1-top-contact-item d-flex align-items-center ">
                            <div className="icon d-flex justify-content-center align-items-center">
                              <i
                                aria-hidden="true"
                                className="fas fa-phone-alt"
                              />
                            </div>
                            <div className="content">
                              <h4 className="subtitle kd-heading-1">
                                call us anytime
                              </h4>
                              <a
                                href="tel:+91 9355500042"
                                aria-label="name"
                                className="link kd-heading-1 d-inline-block text-lowercase"
                              >
                                +91 9355500042
                              </a>
                            </div>
                          </div>
                          <div className="kd-header-1-top-contact-item d-flex align-items-center ">
                            <div className="icon d-flex justify-content-center align-items-center">
                              <i
                                aria-hidden="true"
                                className="far fa-envelope"
                              />
                            </div>
                            <div className="content">
                              <h4 className="subtitle kd-heading-1">
                                Email Us Anytime
                              </h4>
                              <a
                                href="mailto:info@universitybureau.com"
                                aria-label="name"
                                className="link kd-heading-1 d-inline-block text-lowercase"
                              >
                                info@universitybureau.com
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-3163e91 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="3163e91"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-no">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-888d83f"
                data-id="888d83f"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-789f478 elementor-widget elementor-widget-go-pricing-tab"
                    data-id="789f478"
                    data-element_type="widget"
                    data-widget_type="go-pricing-tab.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="kd-price-1-area">
                        <div className="kd-price-1-bg-img fix img-cover">
                          <img
                            decoding="async"
                            src="https://universitybureau.com/content/uploads/2024/06/p1-bg-1.webp"
                            alt=""
                          />
                        </div>
                        <div className="container kd-conainer-1">
                          <div className="kd-price-1-row">
                            {/* left-side */}
                            <div className="kd-price-1-left">
                              {/* tabs-btn */}
                              <ul
                                className="kd-price-1-tabs-btn"
                                id="myTab"
                                role="tablist"
                              >
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link active kd-heading-1 has-clr-white kd-font-800"
                                    id="home-tab0"
                                    data-bs-toggle="tab"
                                    data-bs-target="#home0"
                                    type="button"
                                    role="tab"
                                    aria-controls="home0"
                                    aria-selected="true"
                                  >
                                    <img
                                      decoding="async"
                                      src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                      alt=""
                                    />
                                    Executive eductaion{" "}
                                  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link  kd-heading-1 has-clr-white kd-font-800"
                                    id="home-tab1"
                                    data-bs-toggle="tab"
                                    data-bs-target="#home1"
                                    type="button"
                                    role="tab"
                                    aria-controls="home1"
                                    aria-selected="true"
                                  >
                                    <img
                                      decoding="async"
                                      src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                      alt=""
                                    />
                                    Bachelor's degrees{" "}
                                  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link  kd-heading-1 has-clr-white kd-font-800"
                                    id="home-tab2"
                                    data-bs-toggle="tab"
                                    data-bs-target="#home2"
                                    type="button"
                                    role="tab"
                                    aria-controls="home2"
                                    aria-selected="true"
                                  >
                                    <img
                                      decoding="async"
                                      src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                      alt=""
                                    />
                                    Master's degrees{" "}
                                  </button>
                                </li>
                              </ul>
                              {/* tabs-content */}
                              <div
                                className="tab-content kd-price-1-tabs-content"
                                id="myTabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="home0"
                                  role="tabpanel"
                                  aria-labelledby="home-tab0"
                                >
                                  <div className="kd-price-1-tabs-content-wrap">
                                    <div className="left-img">
                                      <img
                                        decoding="async"
                                        src="https://universitybureau.com/content/uploads/2024/06/p1-img-1.webp"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="tab-pane fade "
                                  id="home1"
                                  role="tabpanel"
                                  aria-labelledby="home-tab1"
                                >
                                  <div className="kd-price-1-tabs-content-wrap">
                                    <div className="left-img">
                                      <img
                                        decoding="async"
                                        src="https://universitybureau.com/content/uploads/2024/06/p1-img-1.webp"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="tab-pane fade "
                                  id="home2"
                                  role="tabpanel"
                                  aria-labelledby="home-tab2"
                                >
                                  <div className="kd-price-1-tabs-content-wrap">
                                    <div className="left-img">
                                      <img
                                        decoding="async"
                                        src="https://universitybureau.com/content/uploads/2024/06/p1-img-1.webp"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* right-content */}
                            <div className="kd-price-1-right">
                              {/* sectiont-title */}
                              <div className="kd-price-1-scn-title mb-30">
                                <h6 className="kd-subtitle-1 kd-subtitle-ani-1 has-clr-2 elementor-kadu-sub">
                                  <img
                                    decoding="async"
                                    src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                                    alt=""
                                  />
                                  About
                                </h6>
                                <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text has-clr-white">
                                  University Bureau Study Abroad Platform
                                </h1>
                                <div
                                  className="elementor-gt-desc kd-para-1 disc has-clr-white"
                                  data-splitting=""
                                >
                                  <p>
                                    University Bureau is an easy-to-use online
                                    platform that brings together over 5,000
                                    recruiters and 1,500 institutions worldwide.
                                    It's like a one-stop shop for all your
                                    education needs.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-89770c0 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="89770c0"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-container elementor-column-gap-no">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-43c9545"
                data-id="43c9545"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <section
                    className="elementor-section elementor-inner-section elementor-element elementor-element-630ff15 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="630ff15"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-default">
                      <div
                        className="elementor-column elementor-inner-column elementor-element elementor-element-b4bd792"
                        data-id="b4bd792"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-77702f4 elementor-widget elementor-widget-go-s-heading"
                            data-id="77702f4"
                            data-element_type="widget"
                            data-widget_type="go-s-heading.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="prthalign">
                                <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                                  <img
                                    decoding="async"
                                    src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                                    alt=""
                                  />
                                  Mission &amp; Vision
                                </h6>
                                <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                                  Know About Us
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    className="elementor-section elementor-inner-section elementor-element elementor-element-c63fd16 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="c63fd16"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-default">
                      <div
                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1225393"
                        data-id={1225393}
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-f1a6ec4 elementor-widget elementor-widget-go-service-box"
                            data-id="f1a6ec4"
                            data-element_type="widget"
                            data-widget_type="go-service-box.default"
                          >
                            <div className="elementor-widget-container">
                              <div
                                className="kd-services-2-card"
                                style={{ height: 370 }}
                              >
                                <div className="icon-wrap d-flex align-items-center flex-wrap">
                                  <div className="icon">
                                    <img
                                      decoding="async"
                                      src="https://universitybureau.com/content/uploads/2024/06/s2-icon-3.webp"
                                      alt="List"
                                    />
                                  </div>
                                </div>
                                <h2 className="card-title kd-heading-1 kd-font-900">
                                    Our Misson
                                </h2>
                                <p className="disc kd-para-1">
                                  University Bureau simplifies international
                                  education for all students. Our mission is to
                                  provide students with the best international
                                  education options by assisting them with the
                                  best. Additionally, our platform facilitates
                                  the finding and collaboration of recruiters
                                  and universities.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9f790af"
                        data-id="9f790af"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-5d094e6 elementor-widget elementor-widget-go-service-box"
                            data-id="5d094e6"
                            data-element_type="widget"
                            data-widget_type="go-service-box.default"
                          >
                            <div className="elementor-widget-container">
                              <div
                                className="kd-services-2-card"
                                style={{ height: 370 }}
                              >
                                <div className="icon-wrap d-flex align-items-center flex-wrap">
                                  <div className="icon">
                                    <img
                                      decoding="async"
                                      src="https://universitybureau.com/content/uploads/2024/06/s2-icon-4.webp"
                                      alt="List"
                                    />
                                  </div>
                                </div>
                                <h2 className="card-title kd-heading-1 kd-font-900">
                                    Our Vision
                                </h2>
                                <p className="disc kd-para-1">
                                  We dream of a world in which everyone has
                                  access to education. University Bureau seeks
                                  to connect students with prestigious
                                  universities, universities with outstanding
                                  faculty, and brilliant minds worldwide.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-515cefe elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="515cefe"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-container elementor-column-gap-no">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-19e0700"
                data-id="19e0700"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-2b5e7ac elementor-widget elementor-widget-go-content-tab"
                    data-id="2b5e7ac"
                    data-element_type="widget"
                    data-widget_type="go-content-tab.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="kd-course-3-area p-relative pt-105 pb-60 fix">
                        <div className="kd-course-1-bg-img img-cover fix">
                          <img
                            decoding="async"
                            src="https://universitybureau.com/content/uploads/2024/06/course-3-bg-1.webp"
                            alt=""
                          />
                        </div>
                        <div className="container kd-container-1 txaa-slide-down-1">
                          {/* sectiont-title */}
                          <div className="kd-course-1-scn-title">
                            <div className="text-center w-100">
                              <h6 className="kd-subtitle-1 kd-subtitle-ani-1 has-clr-2 elementor-kadu-sub">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/star-2.webp"
                                  alt=""
                                />
                                We've designed a streamlined process to make
                                studying abroad accessible for students,
                                recruiters, and institutions.
                              </h6>
                              <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text has-clr-white">
                                How the University Bureau Works
                              </h1>
                            </div>
                          </div>
                          {/* tabs-content */}
                          <div
                            className="tab-content kd-course-3-tabs-content-wrap txaa-slide-down-1-item"
                            id="myTabContent"
                          >
                            <div
                              className="tab-pane fade animated fadeInUp show active"
                              id="home0"
                              role="tabpanel"
                              aria-labelledby="home-tab0"
                            >
                              <div className="kd-course-3-tabs-content d-block">
                                <div className="left-content">
                                  <h4 className="kd-heading-1 kd-font-900 item-title">
                                    Registration
                                  </h4>
                                  <ul>
                                    <li>
                                      <b>For Students:</b> Begin your journey by
                                      creating your profile on our platform.
                                    </li>
                                    <li>
                                      <b>For Recruiters:</b> Explore diverse
                                      student profiles and institutions to find
                                      the best matches.
                                    </li>
                                    <li>
                                      <b>For Institutions:</b> Search for
                                      qualified students that align with your
                                      institution's requirements.
                                    </li>
                                  </ul>
                                </div>
                                <div
                                  className="left-content"
                                  style={{ marginTop: "2rem" }}
                                >
                                  <h4 className="kd-heading-1 kd-font-900 item-title">
                                    Explore Opportunities
                                  </h4>
                                  <ul>
                                    <li>
                                      <b>For Students:</b> Begin your journey by
                                      creating your profile on our platform.
                                    </li>
                                    <li>
                                      <b>For Recruiters:</b> Explore diverse
                                      student profiles and institutions to find
                                      the best matches.
                                    </li>
                                    <li>
                                      <b>For Institutions:</b> Search for
                                      qualified students that align with your
                                      institution's requirements.
                                    </li>
                                  </ul>
                                </div>
                                <div
                                  className="left-content"
                                  style={{ marginTop: "2rem" }}
                                >
                                  <h4 className="kd-heading-1 kd-font-900 item-title">
                                    Application Process
                                  </h4>
                                  <ul>
                                    <li>
                                      <b>For Students:</b> Begin your journey by
                                      creating your profile on our platform.
                                    </li>
                                    <li>
                                      <b>For Recruiters:</b> Explore diverse
                                      student profiles and institutions to find
                                      the best matches.
                                    </li>
                                    <li>
                                      <b>For Institutions:</b> Search for
                                      qualified students that align with your
                                      institution's requirements.
                                    </li>
                                  </ul>
                                </div>
                                <div
                                  className="left-content"
                                  style={{ marginTop: "2rem" }}
                                >
                                  <h4 className="kd-heading-1 kd-font-900 item-title">
                                    End-to-End Support
                                  </h4>
                                  <ul>
                                    <li>
                                      <b>For Students:</b> Begin your journey by
                                      creating your profile on our platform.
                                    </li>
                                    <li>
                                      <b>For Recruiters:</b> Explore diverse
                                      student profiles and institutions to find
                                      the best matches.
                                    </li>
                                    <li>
                                      <b>For Institutions:</b> Search for
                                      qualified students that align with your
                                      institution's requirements.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-e72771e elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="e72771e"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6ca67bc"
                data-id="6ca67bc"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-63a89dd elementor-widget elementor-widget-go-s-heading"
                    data-id="63a89dd"
                    data-element_type="widget"
                    data-widget_type="go-s-heading.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="prthalign">
                        <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                          <img
                            decoding="async"
                            src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                            alt=""
                          />
                          Request for Counseling
                        </h6>
                        <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                          Get In Touch with Our Team
                        </h1>
                        <div
                          className="elementor-gt-desc kd-para-1 disc wow"
                          data-splitting=""
                        >
                          <p>
                            Our team will contact you within the next 24 hours.
                            We appreciate your interest!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-4edb52f elementor-widget elementor-widget-go-ct-info"
                    data-id="4edb52f"
                    data-element_type="widget"
                    data-widget_type="go-ct-info.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="kd-cta-2-content">
                        <div className="contact-item-flex d-flex align-items-center flex-wrap txaa-fade-right">
                          <div className="kd-header-1-top-contact-item d-flex align-items-center ">
                            <div className="icon d-flex justify-content-center align-items-center">
                              <i
                                aria-hidden="true"
                                className="fas fa-phone-alt"
                              />
                            </div>
                            <div className="content">
                              <h4 className="subtitle kd-heading-1">
                                call us anytime
                              </h4>
                              <a
                                href="tel:+91 9355500042"
                                aria-label="name"
                                className="link kd-heading-1 d-inline-block text-lowercase"
                              >
                                +91 9355500042
                              </a>
                            </div>
                          </div>
                          <div className="kd-header-1-top-contact-item d-flex align-items-center ">
                            <div className="icon d-flex justify-content-center align-items-center">
                              <i
                                aria-hidden="true"
                                className="far fa-envelope"
                              />
                            </div>
                            <div className="content">
                              <h4 className="subtitle kd-heading-1">
                                Email Us Anytime
                              </h4>
                              <a
                                href="mailto:info@universitybureau.com"
                                aria-label="name"
                                className="link kd-heading-1 d-inline-block text-lowercase"
                              >
                                info@universitybureau.com
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-2d0cff8"
                data-id="2d0cff8"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-88c702a elementor-widget elementor-widget-go-ct-form"
                    data-id="88c702a"
                    data-element_type="widget"
                    data-widget_type="go-ct-form.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="txaa-slide-down-1">
                        <div
                          className="kd-cta-2-form bg-default txaa-slide-down-1-item"
                          data-background="https://universitybureau.com/content/uploads/2024/06/cta-2-form-bg-1.webp"
                        >
                          <div
                            className="wpcf7 no-js"
                            id="wpcf7-f504-p1845-o1"
                            lang="en-US"
                            dir="ltr"
                          >
                            <div className="screen-reader-response">
                              <p
                                role="status"
                                aria-live="polite"
                                aria-atomic="true"
                              />
                              <ul />
                            </div>
                            <AboutForm></AboutForm>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default About;
